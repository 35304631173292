<template>
  <BONotification ref="boNotification" />
  <div
      class="plugandpos-wrap" :class="'lang-' + currentRoute.params.locale +
    ' route-' + currentRoute.name +
    (showMenu ? ' menu-shown' : ' menu-hidden')">
    <Toast position="top-center">
      <template #message="slotProps">
        <div class="flex justify-content-between align-items-center" :class="'severity-' + slotProps.message.severity">
          <div class="mr-2">
            <i v-if="slotProps.message.severity == 'success'" class="fas fa-check" />
            <i v-if="slotProps.message.severity == 'info'" class="fas fa-info-circle" />
            <i v-if="slotProps.message.severity == 'warn'" class="fas fa-exclamation-circle" />
            <i v-if="slotProps.message.severity == 'error'" class="fas fa-exclamation-triangle" />
          </div>
          <div class="">
            <h4>{{ slotProps.message.summary }}</h4>
            <p>{{ slotProps.message.detail }}</p>
          </div>
          <!--
          <div class="grid p-fluid">
            <div class="col-6">
              <Button class="p-button-success" label="Yes" @click="onConfirm" />
            </div>
            <div class="col-6">
              <Button class="p-button-secondary" label="No" @click="onReject" />
            </div>
          </div>
          -->
        </div>
      </template>
    </Toast>
    <!--  group="templating" -->
    <ConfirmDialog
        :draggable="false"
        :pt="{
          acceptButton: {
            label: t('boolean_type_true')
          }
          , rejectButton: { label: t('boolean_type_false') }
        }">

      <template #message="slotProps">
        <div class="flex p-4">
          <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
          <p class="pl-2">{{ slotProps.message.message }}</p>
        </div>
      </template>
    </ConfirmDialog>

    <div v-if="authState.state.globalUser">
      <div class="bo-sidebar">
        <div class="bo-sidebar-header">
          <img class="plugandpos-icon" width="220" :src="require('@/assets/logo-restomax.svg')" />
          <div v-if="!(authState.state.organization?.is_system ?? false)" class="selector-container">
            <entity-selector />
            <store-selector />
          </div>
        </div>

        <div id="nav">
          <SidebarLevel1
              :show='showMenu'
              @language:updated='changeLanguage'
              @show:updated='(value: boolean) => showMenu = value' />
          <SidebarLevel2 />
        </div>

        <div class="appVersion">v{{ version }}</div>
      </div>
    </div>
    <div class="bo-content">
      <OrganizationSystemBanner />

      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import EntitySelector from '@/components/EntitySelector.vue'
import SidebarLevel1 from '@/components/Layout/Sidebar/SidebarLevel1.vue'
import SidebarLevel2 from '@/components/Layout/Sidebar/SidebarLevel2.vue'
import OrganizationSystemBanner from '@/components/OrganizationSystem/OrganizationSystemBanner.vue'
import StoreSelector from '@/components/StoreSelector.vue'
import { useAuthState } from '@/states/auth'
import { groupBy } from 'lodash'
import { usePrimeVue } from 'primevue/config'
import { defineComponent, getCurrentInstance, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import filters from '@/helpers/filters';
import BONotification from '@/components/Dialog/BONotification.vue';
import useAppState from '@/states/app';

const packageJson = require('../package.json')

export default defineComponent({
  components: {
    BONotification,
    SidebarLevel1,
    SidebarLevel2,
    EntitySelector,
    StoreSelector,
    OrganizationSystemBanner
  },
  setup() {
    const authState = useAuthState()
    const appState = useAppState();
    const app = getCurrentInstance()
    const { currentRoute } = useRouter()
    const i18n = useI18n();
    filters.init(i18n.t);
    const boNotification = ref();

    const primevue = usePrimeVue()
    const showMenu = ref(true)

    app!.appContext.config.globalProperties.i18n = i18n
    app!.appContext.config.globalProperties.$t = i18n.t

    const t = i18n.t
    const version = packageJson.version

    const globalUserWatcher = watch(() => authState.state.globalUser, () => {
      loadPrimeLocale()
    })

    const loadPrimeLocale = () => {
      if (primevue.config.locale) {
        switch (i18n.locale.value) {
          case 'fr':
            primevue.config.locale.startsWith = 'Commence par'
            primevue.config.locale.contains = 'Contient'
            primevue.config.locale.notContains = 'Ne contient pas'
            primevue.config.locale.endWith = 'Termine par'
            primevue.config.locale.equals = 'Égal'
            primevue.config.locale.notEquals = 'N\'est pas égal'
            primevue.config.locale.noFilter = 'Aucun filtre'
            break
          case 'nl':
            primevue.config.locale.startsWith = 'Begint met'
            primevue.config.locale.contains = 'Bevat'
            primevue.config.locale.notContains = 'Bevat niet'
            primevue.config.locale.endWith = 'Eindigt met'
            primevue.config.locale.equals = 'Is gelijk aan'
            primevue.config.locale.notEquals = 'Is niet gelijk aan'
            primevue.config.locale.noFilter = 'Geen filter'
            break
          case 'en':
            primevue.config.locale.startsWith = 'Starts with'
            primevue.config.locale.contains = 'Contains'
            primevue.config.locale.notContains = 'Not contains'
            primevue.config.locale.endWith = 'Ends with'
            primevue.config.locale.equals = 'Equals'
            primevue.config.locale.notEquals = 'Not equals'
            primevue.config.locale.noFilter = 'No Filter'
            break
        }
      }
    }

    const changeLanguage = () => {
      loadPrimeLocale()
    }

    watch(() => appState.getNotification,
        async notification => {
          if (notification?.show) {
            // Replace msg in V1
            appState.setNotificationValue(await boNotification.value.show({
              type: 'error',
              subtitle: filters.cflt('GenericWords.RestError'),
              message: `<div class="w-full text-center pt-3 pb-6">${
                            i18n.locale.value === 'fr' ? 'Réintroduisez votre requête en limitant le nombre de stores ou l&rsquo;étendue de la période.' :
                            i18n.locale.value === 'nl' ? 'Dien uw verzoek opnieuw in door het aantal winkels of de periode te beperken' :
                            'Reintroduce your request by limiting the number of stores or the time period.'
                        }</div>`
              ,
              showSend: false
            }, notification?.custom));
          }
        }
    )

    return {
      boNotification,
      showMenu,
      authState,
      changeLanguage,
      globalUserWatcher,
      version,
      currentRoute,
      t,
      groupBy,
    };
  },
});
</script>
